import * as React from 'react';
import { styled } from '@linaria/react';
import { ArrowRight } from 'lucide-react';

import Link from '@/components/Link';
import { ExpandableGroupContext } from '@/components/MobileMenu/ExpandableGroup';

import { COURSES } from './CoursesList.data';

function CoursesList({ isOpen }: { isOpen: boolean }) {
  return (
    <>
      <CSSMascot
        src="/images/css-for-js-mascot-light.png"
        alt="Joy of React mascot"
        style={{
          opacity: isOpen ? 1 : 0,
          transform: isOpen ? 'translateY(30%)' : 'translateY(60%)',
          transition: isOpen
            ? 'all 400ms 200ms'
            : 'opacity 200ms 0ms, transform 400ms 200ms',
        }}
      />
      <SubList>
        {COURSES.map(({ title, slug, href }) => (
          <li key={slug}>
            <SubNavLink href={href}>
              <ArrowRight strokeWidth={2} size="1rem" /> {title}
            </SubNavLink>
          </li>
        ))}

        <StudentLoginListItem>
          <Button href="https://courses.joshwcomeau.com">
            Student Login
          </Button>
        </StudentLoginListItem>
      </SubList>
    </>
  );
}

function ConnectedCoursesList() {
  const isOpen = React.useContext(ExpandableGroupContext);

  return <CoursesList isOpen={isOpen} />;
}

const CSSMascot = styled.img`
  position: absolute;
  bottom: 0;
  right: 16px;
  transform: translateY(30%);
  width: min(35%, 200px);

  @media (max-width: 30rem) {
    right: -32px;
  }
`;

const SubList = styled.ul`
  padding: 8px;
  padding-bottom: 16px;
  font-size: 0.875rem;
  list-style-type: none;
`;

const SubNavLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  text-decoration: none;
  color: inherit;
`;
const StudentLoginListItem = styled.li`
  margin-top: 2rem;
  padding-bottom: 0.5rem;
  padding-left: 8px;
`;

const Button = styled(Link)`
  padding: 10px 28px;
  border-radius: 4px;
  background: hsl(200deg 50% 50% / 0.25);
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: var(--font-weight-medium);
  color: inherit;
`;

export default ConnectedCoursesList;
